import "./svg.css";
function GitHub(): JSX.Element {
  return (
    <svg
      className="socials github"
      xmlns="http://www.w3.org/2000/svg"
      width="46.448"
      height="45.287"
      viewBox="0 0 46.448 45.287"
    >
      <filter id="github-change-color">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values="0.98 0   0   0   0
                  0   0  0   0   0
                  0   0   0  0   0
                  0   0   0   1   0 "
        />
      </filter>
      <path
        id="Icon_awesome-github"
        data-name="Icon awesome-github"
        d="M15.536,37.028c0,.187-.215.337-.487.337-.309.028-.524-.122-.524-.337,0-.187.215-.337.487-.337C15.292,36.663,15.536,36.812,15.536,37.028Zm-2.912-.421c-.066.187.122.4.4.459a.439.439,0,0,0,.581-.187c.056-.187-.122-.4-.4-.487A.481.481,0,0,0,12.623,36.606Zm4.139-.159c-.272.066-.459.243-.431.459.028.187.272.309.553.243s.459-.243.431-.431S17.034,36.419,16.762,36.447ZM22.924.563A22.455,22.455,0,0,0,0,23.412a23.478,23.478,0,0,0,15.873,22.4c1.2.215,1.62-.524,1.62-1.133,0-.581-.028-3.783-.028-5.75,0,0-6.555,1.4-7.932-2.791,0,0-1.068-2.725-2.6-3.427,0,0-2.144-1.47.15-1.442a4.943,4.943,0,0,1,3.615,2.416c2.051,3.615,5.488,2.575,6.827,1.957a5.2,5.2,0,0,1,1.5-3.156C13.784,31.905,8.5,31.147,8.5,22.138a7.1,7.1,0,0,1,2.21-5.516,8.848,8.848,0,0,1,.243-6.358c1.957-.609,6.461,2.528,6.461,2.528a22.123,22.123,0,0,1,11.762,0s4.5-3.146,6.461-2.528a8.844,8.844,0,0,1,.243,6.358c1.5,1.658,2.416,2.95,2.416,5.516,0,9.037-5.516,9.758-10.75,10.348a5.538,5.538,0,0,1,1.592,4.345c0,3.156-.028,7.061-.028,7.829,0,.609.431,1.348,1.62,1.133A23.261,23.261,0,0,0,46.448,23.412C46.448,10.423,35.913.563,22.924.563ZM9.1,32.861c-.122.094-.094.309.066.487.15.15.365.215.487.094.122-.094.094-.309-.066-.487C9.439,32.8,9.224,32.739,9.1,32.861ZM8.091,32.1c-.066.122.028.272.215.365a.279.279,0,0,0,.4-.066c.066-.122-.028-.272-.215-.365C8.306,31.98,8.156,32.008,8.091,32.1Zm3.034,3.334c-.15.122-.094.4.122.581.215.215.487.243.609.094.122-.122.066-.4-.122-.581C11.528,35.314,11.247,35.286,11.125,35.436Zm-1.068-1.377c-.15.094-.15.337,0,.553s.4.309.524.215a.427.427,0,0,0,0-.581C10.451,34.031,10.207,33.937,10.057,34.059Z"
        transform="translate(0 -0.563)"
        fill="#fff"
      />
    </svg>
  );
}

export default GitHub;
