import React from "react";
import "./technologies.css";
const Technologies: any = () => {
  return [
    <React.Fragment>
      <a
        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/javascript-colored.svg"
          width="36"
          height="36"
          alt="JavaScript"
          className="technologies-img"
        />
      </a>
      <a
        href="https://www.python.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/python-colored.svg"
          width="36"
          height="36"
          alt="Python"
          className="technologies-img"
        />
      </a>
      <a
        href="https://www.typescriptlang.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/typescript-colored.svg"
          width="36"
          height="36"
          alt="TypeScript"
          className="technologies-img"
        />
      </a>
      <a
        href="https://developer.mozilla.org/en-US/docs/Glossary/HTML5"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/html5-colored.svg"
          width="36"
          height="36"
          alt="HTML5"
          className="technologies-img"
        />
      </a>
      <a
        href="https://www.w3.org/TR/CSS/#css"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/css3-colored.svg"
          width="36"
          height="36"
          alt="CSS3"
          className="technologies-img"
        />
      </a>
      <a
        href="https://reactjs.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/react-colored.svg"
          width="36"
          height="36"
          alt="React"
          className="technologies-img"
        />
      </a>
      <a
        href="https://sass-lang.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/sass-colored.svg"
          width="36"
          height="36"
          alt="Sass"
          className="technologies-img"
        />
      </a>
      <a
        href="https://getbootstrap.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/bootstrap-colored.svg"
          width="36"
          height="36"
          alt="Bootstrap"
          className="technologies-img"
        />
      </a>
      <a
        href="https://webpack.js.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/webpack-colored.svg"
          width="36"
          height="36"
          alt="Webpack"
          className="technologies-img"
        />
      </a>
      <a
        href="https://babeljs.io/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/babel-colored.svg"
          width="36"
          height="36"
          alt="Babel"
          className="technologies-img"
        />
      </a>
      <a
        href="https://nodejs.org/en/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/nodejs-colored.svg"
          width="36"
          height="36"
          alt="NodeJS"
          className="technologies-img"
        />
      </a>
      <a
        href="https://expressjs.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/express-colored.svg"
          width="36"
          height="36"
          alt="Express"
          className="technologies-img"
        />
      </a>
      <a
        href="https://www.mongodb.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/mongodb-colored.svg"
          width="36"
          height="36"
          alt="MongoDB"
          className="technologies-img"
        />
      </a>
      <a
        href="https://www.postgresql.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/postgresql-colored.svg"
          width="36"
          height="36"
          alt="PostgreSQL"
          className="technologies-img"
        />
      </a>
      <a
        href="https://www.heroku.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/heroku-colored.svg"
          width="36"
          height="36"
          alt="Heroku"
          className="technologies-img"
        />
      </a>
      <a
        href="https://flask.palletsprojects.com/en/2.0.x/"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/flask-colored.svg"
          width="36"
          height="36"
          alt="Flask"
          className="technologies-img"
        />
      </a>
      <a
        href="https://www.adobe.com/uk/products/xd.html"
        target="_blank"
        rel="noopener noreferrer"
        className="technologies-link"
      >
        <img
          src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/xd-colored.svg"
          width="36"
          height="36"
          alt="XD"
          className="technologies-img"
        />
      </a>
    </React.Fragment>,
  ];
};

export default Technologies;
