import "./svg.css";
function Logo(): JSX.Element {
  return (
    <svg
      className="logo"
      xmlns="http://www.w3.org/2000/svg"
      width="97.311"
      height="77.837"
      viewBox="0 0 97.311 77.837"
    >
      <path
        id="Icon_awesome-code"
        data-name="Icon awesome-code"
        d="M42.405,77.764l-9.274-2.691a1.827,1.827,0,0,1-1.247-2.265L52.637,1.32A1.827,1.827,0,0,1,54.9.073l9.274,2.691a1.827,1.827,0,0,1,1.247,2.265L44.671,76.517a1.822,1.822,0,0,1-2.265,1.247ZM25.073,60.705l6.614-7.055a1.824,1.824,0,0,0-.122-2.615L17.79,38.918,31.565,26.8a1.812,1.812,0,0,0,.122-2.615l-6.614-7.055a1.826,1.826,0,0,0-2.585-.076L.58,37.581a1.812,1.812,0,0,0,0,2.661l21.909,20.54a1.813,1.813,0,0,0,2.585-.076ZM74.82,60.8l21.909-20.54a1.812,1.812,0,0,0,0-2.661L74.82,17.04a1.841,1.841,0,0,0-2.585.076l-6.614,7.055a1.824,1.824,0,0,0,.122,2.615L79.518,38.918,65.743,51.036a1.812,1.812,0,0,0-.122,2.615l6.614,7.055a1.827,1.827,0,0,0,2.585.091Z"
        transform="translate(0.002 -0.003)"
        fill="#fff"
      />
    </svg>
  );
}

export default Logo;
