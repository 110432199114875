import "./svg.css";
function Instagram(): JSX.Element {
  return (
    <svg
      className="socials instagram"
      xmlns="http://www.w3.org/2000/svg"
      width="45.297"
      height="45.287"
      viewBox="0 0 45.297 45.287"
    >
      <filter id="instagram-change-color">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=".98 0   0   0   0
                  0   0  0   0   0
                  0   0   0  0   0
                  0   0   0   1   0 "
        />
      </filter>
      <path
        id="Icon_awesome-instagram"
        data-name="Icon awesome-instagram"
        d="M22.648,13.27A11.611,11.611,0,1,0,34.26,24.881,11.593,11.593,0,0,0,22.648,13.27Zm0,19.16A7.549,7.549,0,1,1,30.2,24.881,7.562,7.562,0,0,1,22.648,32.43ZM37.443,12.8a2.708,2.708,0,1,1-2.708-2.708A2.7,2.7,0,0,1,37.443,12.8Zm7.69,2.749c-.172-3.628-1-6.841-3.658-9.489S35.614,2.579,31.986,2.4c-3.739-.212-14.946-.212-18.685,0-3.618.172-6.831,1-9.489,3.648S.336,11.906.154,15.534c-.212,3.739-.212,14.946,0,18.685.172,3.628,1,6.841,3.658,9.489s5.861,3.476,9.489,3.658c3.739.212,14.946.212,18.685,0,3.628-.172,6.841-1,9.489-3.658s3.476-5.861,3.658-9.489c.212-3.739.212-14.936,0-18.675ZM40.3,38.23a7.642,7.642,0,0,1-4.3,4.3c-2.981,1.182-10.055.909-13.349.909S12.27,43.708,9.3,42.535a7.642,7.642,0,0,1-4.3-4.3c-1.182-2.981-.909-10.055-.909-13.349S3.822,14.5,4.994,11.532a7.642,7.642,0,0,1,4.3-4.3c2.981-1.182,10.055-.909,13.349-.909S33.027,6.055,36,7.227a7.642,7.642,0,0,1,4.3,4.3c1.182,2.981.909,10.055.909,13.349S41.485,35.26,40.3,38.23Z"
        transform="translate(0.005 -2.238)"
        fill="#fff"
      />
    </svg>
  );
}

export default Instagram;
